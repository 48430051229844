<template>
  <div class="template-6">
    <TemplateHeader />
    <TemplateHeroHeader />
    <TemplateOpenContent v-if="candidate.openContent" />
    <TemplateFeatureText v-if="candidate.featureText || candidate.featureTitle" />
    <TemplateMediaBlock />
    <div class="template-endorsements">
      <TemplateEndorsements v-if="showEndorsements" />
      <TemplateEndorsementList />
    </div>
    <TemplateEvents3 />
    <TemplateContactInfo3 />
  </div>
</template>

<script>
import TemplateHeader from "@/components/public/Header.vue";
import TemplateHeroHeader from "@/components/public/HeroHeader.vue";
import TemplateOpenContent from "@/components/public/OpenContent.vue";
import TemplateFeatureText from "@/components/public/FeatureText.vue";
import TemplateMediaBlock from "@/components/public/MediaBlock.vue";
import TemplateEndorsements from "@/components/public/Endorsements.vue";
import TemplateEvents3 from "@/components/public/Events3.vue";
import TemplateContactInfo3 from "@/components/public/ContactInfo3.vue";
import TemplateEndorsementList from "@/components/public/EndorsementList.vue";

import { ThemeColorLoader } from "@/services/helpers";

export default {
  data() {
    return {
      title: "Template 6"
    };
  },
  components: {
    TemplateHeader,
    TemplateHeroHeader,
    TemplateOpenContent,
    TemplateFeatureText,
    TemplateMediaBlock,
    TemplateEndorsements,
    TemplateEvents3,
    TemplateContactInfo3,
    TemplateEndorsementList
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
     showEndorsements() {
      return this.$store.getters["candidates/showEndorsementMenuItem"]
    } 
  },
  mounted() {
    ThemeColorLoader(this.candidate.profileColor);

    // Sticky Menu Script for Template 6 ------------------------------------- //

    window.onscroll = function() {
      myFunction();
    }

    function myFunction() {
      
      let navbar = document.querySelector('.site-header');
      let sticky = navbar.getBoundingClientRect().top + 60;

      if (window.pageYOffset > sticky) {
        navbar.classList.add('sticky');
      } else {
        navbar.classList.remove('sticky');
      }
    }

    // Removing image container when no image present ----------//

    let eventImg = document.querySelectorAll('.template-events-3 .event-img img');

    for (let i = 0; i < eventImg.length; i++) {

      let imgParent = eventImg[i].parentNode;
      let parentSibling = imgParent.nextSibling;

      if ( !eventImg[i].getAttribute('src') ) {
        imgParent.classList.add('d-none');
        parentSibling.classList.add('col-md-10');
      }

    }
    
    // Removing Null link from event cards ---------------------//

    let eventLinks = document.querySelectorAll('.template-events-3 .card a');

    for  (let i = 0; i < eventLinks.length; i++) {

      let linkText = eventLinks[i].textContent;

      if ( linkText === 'null' ) {
        eventLinks[i].classList.add('d-none');
      }

    }

  }
};
</script>

<style lang="scss">
@import "@/assets/styles/public/theme/_base.scss";
@import "@/assets/styles/public/theme/template-6.scss";
</style>
